.List {
  width: 100%;
  border: 1px solid #ddd;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 25px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.placeholder {
  display: inline-block;
  height: 1em;
  background-color: #ddd;
}

.cacheButtonAndCountRow {
  display: flex;
  align-items: center;
}
.button {
  flex: 0 0 auto;
  background-color: #4db6ac;
  color: #fff;
  appearance: none;
  border: none;
  padding: 0.5em 1em;
  border-radius: 0.35em;
  font-size: 1em;
}
.cacheCountRow {
  flex: 1 1 auto;
  text-align: right;
  color: #bdbdbd;
  font-size: 0.75em;
  font-weight: 100;
}
.ReactVirtualized__Table__row:nth-of-type(2n + 1) {
  background: #ebebeb;
}
.ReactVirtualized__Table__row {
  cursor: pointer;
}
.ReactVirtualized__Table__row:hover {
  background-color: #dadada;
}
.modal-overlay, .app-loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  overflow: hidden;
}
.app-loader {
  background: #fff;
}
.loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-overlay {
  background: hsla(0, 0%, 0%, 0.5);
}
.modal-box {
  max-width: 1024px;
  max-height: calc(100vh - 10rem);
  min-height: 50vh;
  margin: 5rem auto;
  border-radius: 1rem;
  border: 0;
  padding: 0;
  overflow: hidden;
}
.modal-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 1.6rem;
  border-bottom: 1px solid #dadada;
}
.modal-header button {
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  position: absolute;
  right: 1rem;
  top: 1.2rem;
  background: #fff;
  border: 0;
}
.modal-content h2 {
  margin: 0;
}
.modal-content {
  padding: 2rem;
  max-height: calc(100vh - 18rem);
  overflow: auto;
}
.modal-content {
  line-height: 1.5;
}
.modal-content pre {
  background: #282c34;
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 100%;
  overflow-x: auto;
}
.modal-content img {
  max-width: 100%;
}
:not(pre) > code {
  background: #e4e6e8;
  padding: 0.1rem 0.3rem;
  border-radius: 0.3rem;
}
.table-wrapper {
  max-width: 1024px;
  padding: 1rem;
  margin: 2rem auto 0;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.ReactVirtualized__Table__headerRow {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}
.main-heading {
  margin: 0 0 0.5rem
}
.help-text {
  margin-top: 0;
}
.cell-shimmer {
  display: block;
  background: #000;
}